import React, { useState, useEffect } from 'react';

import WSSendTest from './WSSendTest';
import { Typography, TextField, Button, Card, CardActions, CardContent } from '@mui/material';


const Home = () => {

  const [newSessionName, setNewSessionName] = React.useState('default');



  return <>
    
    <Card>
      <CardContent>
      <Typography variant="h6">Simulate eeg signal producer</Typography>
        <TextField label="Session name" value={newSessionName} onChange={(e) => setNewSessionName(e.target.value)} />
      </CardContent>
      <CardActions>
        <WSSendTest sessionId={newSessionName}/>
      </CardActions>
    </Card>


  </>
}

export default Home;