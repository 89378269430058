import { Typography, TextField, Button, Card, CardActions, CardContent, Divider } from '@mui/material';
import React, { useEffect } from 'react';

import demoService from '../services/DemoService';



export const WSSendTest = ({ sessionId }) => {
  const [isProducing, setIsProducing] = React.useState(false);

  const startProducing = () => {
    demoService.producerStart(sessionId)
    setIsProducing(true)
  }

  const stopProducing = () => {
    demoService.producerStop(sessionId);
    setIsProducing(false)
  }

  const streamingButton = isProducing ? (<Button onClick={stopProducing}>Stop Producing</Button>) :
    (<Button variant="outlined" color="primary" onClick={startProducing}>Start Producing</Button>)

  return <>
    {streamingButton}
  </>
}


export default WSSendTest;