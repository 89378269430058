import { DemoApi, Configuration } from '../rest';



/**
 * Contextual data querying.
 * @memberof Services
 */
export class DemoService {

  /**
   * The context api.
   */
  api: DemoApi;

  /**
   * @constructor
   */
  constructor () {
    this.api = new DemoApi();
  }

  producerStart (sessionId) {
    return this.api.producerStart({sessionId: sessionId});
  }

  producerStop(sessionId) {
    return this.api.producerStop({sessionId: sessionId})
  }
}

export default new DemoService();