import { ThemeOptions } from '@mui/material/styles';


export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#028c9a',
        },
        secondary: {
            main: '#f50057',
        },
    },
}

export default themeOptions;
