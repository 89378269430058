/* tslint:disable */
/* eslint-disable */
/**
 * Realtime seizure detection
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ProducerStartRequest {
    sessionId: string;
}

export interface ProducerStopRequest {
    sessionId: string;
}

/**
 * 
 */
export class DemoApi extends runtime.BaseAPI {

    /**
     * Starts producing into kafka
     */
    async producerStartRaw(requestParameters: ProducerStartRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling producerStart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/demo/producer/start/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Starts producing into kafka
     */
    async producerStart(requestParameters: ProducerStartRequest, initOverrides?: RequestInit): Promise<void> {
        await this.producerStartRaw(requestParameters, initOverrides);
    }

    /**
     * Stops producing into kafka
     */
    async producerStopRaw(requestParameters: ProducerStopRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling producerStop.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/demo/producer/stop/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stops producing into kafka
     */
    async producerStop(requestParameters: ProducerStopRequest, initOverrides?: RequestInit): Promise<void> {
        await this.producerStopRaw(requestParameters, initOverrides);
    }

}
